import React from 'react';
import { Link } from 'gatsby';
import logo from '../../assets/images/logo.png';
import footerMap from '../../assets/images/footer-map.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
              <p>
                ByteForge is a technology consultancy firm. We specialize in
                crafting digital solutions for businesses, including web &
                mobile applications, data & AI solutions, user experience
                designs and business automations tailored to your needs.
              </p>

              <ul className="social-link">
                <li>
                  <a
                    href="https://www.linkedin.com/company/byteforgestudio"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </li>
                {/* <li>
                  <a
                    href="#"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://twitter.com/byteforgestudio"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Explore</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/portfolio">Our Work</Link>
                </li>
                <li>
                  <Link to="/#">Careers</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>Services</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/services">Application Development</Link>
                </li>
                <li>
                  <Link to="/services">Data & AI</Link>
                </li>
                <li>
                  <Link to="/services">Cloud</Link>
                </li>
                <li>
                  <Link to="/services">Customer Experience</Link>
                </li>
                <li>
                  <Link to="/services">Business Automation</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <h3>Get In Touch</h3>

              <ul className="footer-contact-info">
                <li>
                  <i className="bx bx-phone-call"></i>
                  <a href="tel:+971508945608">(+971) 508-945608</a>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:hello@byteforge.studio">
                    hello@byteforge.studio
                  </a>
                </li>
                <li>
                  <i className="bx bx-map"></i>
                  Dubai, UAE
                </li>
                <li>
                  <i className="bx bx-map"></i>
                  Lahore, Pakistan
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <p style={{ textAlign: 'center' }}>
                Copyright © {currentYear} <strong>ByteForge (Pvt) Ltd.</strong>{' '}
                All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div>
    </footer>
  );
};

export default Footer;
